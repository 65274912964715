import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MarketingHttp, Unsubscriber } from 'lib-core';
import { BehaviorSubject, interval } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { OverflowHandlerService } from '../../services/overflow-handler.service';
import {
  NotificationPopupByDate,
  NotificationPopupByType,
  PlatformType
} from '../../store/models/notification-popups.model';
import { NewInputModule } from '../../shared/components/HOC/forms/new-input/new-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideDirective } from '../../shared/directives/clickOutside.directive';
import { NotificationPopupComponent } from './notification/notification-popup.component';
import { SuccessPopupComponent } from './success/success-popup.component';
import { NotificationPopupMainService } from './notification-popup-main.service';

@Component({
  selector: 'app-notification-popup-main',
  templateUrl: './notification-popup-main.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NewInputModule,
    TranslateModule,
    ClickOutsideDirective,
    NotificationPopupComponent,
    SuccessPopupComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationPopupMainComponent extends Unsubscriber {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public notificationPopupMainService: NotificationPopupMainService
  ) {
    super();
  }
}
