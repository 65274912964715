<input
  #input
  aria-label="Input field"
  (focus)="focusStatus.emit(true)"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange()"
  [type]="inputUIType"
  [ngStyle]="
    promoCodeError && promoCodeError !== PromoCodeErrorTypes.NotVerified
      ? { border: '1px solid red', 'border-color': '1px solid red' }
      : {}
  "
  [disabled]="isDisabled"
  [autocomplete]="autocomplete"
  (blur)="onTouched()"
  (focus)="toggleFocus(true)"
  (focusout)="toggleFocus(false)"
  class=" _x_outline-none _x_whitespace-nowrap _x_items-center _x_flex _x_overflow-hidden _x_cursor-pointer _x_relative _x_text-3 _x_rounded-8 _x_text-dark _x_border-1 _x_border-dark-200 focus:_x_border-black-100 _x_font-medium _x_pr-10 _x_pb-0 _x_pl-10 _x_w-full _x_pt-8 _x_h-24 ng-pristine ng-valid ng-touched {{
    classList.input
  }}"
  [maxLength]="maxLength"
  [mask]="maskk"
  thousandSeparator=","
  [separatorLimit]="separatorLimit"
  [class._s_has-value]="_value?.length > 0"
  [class.ng-invalid]="false"
  appInputWothoutNumber
  [withoutNumber]="withoutNumber" />
<!--focusStatus.emit(false)-->

<label
  aria-label="Input label"
  [ngClass]="isFocused || (!isFocused && _value > 0) ? '_x_text-1' : '_x_top-8 _x_text-3'"
  class="_x_ease-in-out _x_duration-300 group-focus:_x_text-red _x_absolute _x_left-10 _x_top-4 _x_h-7 _x_flex _x_items-center _x_text-dark-700 _x_font-medium _x_pointer-events-none {{
    classList.label
  }}"
  >{{ placeholder }}</label
>
<span
  aria-label="Required field indicator"
  [hidden]="!require"
  [ngStyle]="{ color: isInvalid ? '#F12D2D' : '' }"
  style="top: 50%; right: 50px; transform: translate(0, -50%)"
  class="test visibility-eye _x_absolute _x_cursor-pointer _x_text-dark-200"
  >*
</span>

<!--    [pattern]="_pattern"-->
<div
  aria-label="Toggle password visibility"
  class="_s_h-color _x_absolute _x_right-12 _x_top-0 _x_z-1 _x_flex _x_h-full _x_cursor-pointer _x_justify-center"
  [class.active]="inputUIType === 'text'"
  (click)="toggleInputType()"
  *ngIf="inputUIType === 'password'">
  <i
    class="_x_icon _x_icon-eye _x_flex _x_h-full _x_min-h-full _x_w-10 _x_min-w-10 _x_items-center _x_justify-center _x_text-7"></i>
</div>
