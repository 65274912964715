export const environment = {
  production: false,
  develop: true,
  staging: false,
  cmsURL: 'https://catalog.dev.extra.ge/',
  customersURL: 'https://customer.dev.extra.ge/',
  reportingURL: 'https://reporting.dev.extra.ge/',
  paymentURL: 'https://payment.dev.extra.ge/',
  orderingURL: 'https://ordering.dev.extra.ge/',
  basketUrl: 'https://basket.dev.extra.ge/',
  apiUrl: 'https://identity.dev.extra.ge/',
  emailUrl: 'https://notification.dev.extra.ge/',
  issuerUrl: 'https://identity.dev.extra.ge',
  marketingURL: 'https://marketing.dev.extra.ge/',
  tradeInUrl: 'https://tradein-api.dev.extra.ge/',
  cdnUrl: 'https://cdn.dev.extra.ge/',
  baseUrl: 'https://dev.extra.ge',
  juridicalUrl: 'https://juridicalidentity.dev.extra.ge/',
  juridicalIssuerUrl: 'https://juridicalidentity.dev.extra.ge',
  adultCallback: 'catalog/bavshvta-samqaro',
  mercuryURL: 'https://mercury.dev.extra.ge/',
  'wall-e': 'https://wall-e.dev.extra.ge/',
  deliveryCalculationApi: 'https://delivery-datecalculation-api.dev.extra.ge/',
  deliveryIntergrationURL: 'https://delivery-integration-api.dev.extra.ge/',
  recaptchaSiteKey: '6Lcdkb4qAAAAAOSlBwO0feZUndIKnTKXG5OwtzAR'
};
