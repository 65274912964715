import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReloadService {
  private jobsInProgress = 0;
  private reloadRequested = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  public startJob(): void {
    this.jobsInProgress++;
  }

  public finishJob(): void {
    if (this.jobsInProgress > 0) {
      this.jobsInProgress--;
    }

    if (this.jobsInProgress === 0 && this.reloadRequested) {
      this.triggerReload();
    }
  }

  public requestReload(): void {
    if (this.jobsInProgress > 0) {
      this.reloadRequested = true;
    } else {
      this.triggerReload();
    }
  }

  private triggerReload(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.location.reload();
    }
  }
}
